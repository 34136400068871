<template>
  <div
    style="
      border: 1px solid rgba(0, 0, 0, 0.2);
      background-color: #fff;
      border-radius: 12px;
      width: 100%;
    "
  >
    <div class="main-container">
      <editor-menu-bar
        v-slot="{ commands, isActive }"
        class="mb-0.3 menu-bar-container"
        :editor="editor"
      >
        <div>
          <div
            :class="{ active: isActive.bold() }"
            class="menu-bar-icon d-inline-block"
            style="
              width: 25px;
              height: 25px;
              margin-left: 10px;
              text-align: center;
              padding-left: 3.5px;
            "
          >
            <i
              class="fas fa-bold text-left mr-1"
              alt=""
              style="cursor: pointer; position: relative; left: 3px"
              @click="commands.bold"
            ></i>
          </div>

          <div
            :class="{ active: isActive.italic() }"
            class="menu-bar-icon d-inline-block"
            style="
              width: 25px;
              height: 25px;
              text-align: center;
              padding-left: 3.5px;
            "
          >
            <i
              class="fas fa-italic text-left mr-1"
              alt=""
              style="
                width: 17px;
                height: 17px;
                border-radius: 100%;
                cursor: pointer;
                position: relative;
                left: 3px;
              "
              @click="commands.italic"
            ></i>
          </div>
          <div
            :class="{ active: isActive.strike() }"
            class="menu-bar-icon d-inline-block"
            style="width: 25px; height: 25px"
          >
            <i
              class="fas fa-strikethrough text-left mr-1"
              alt=""
              style="
                width: 17px;
                height: 17px;
                border-radius: 100%;
                cursor: pointer;
                position: relative;
                left: 3px;
              "
              @click="commands.strike"
            ></i>
          </div>

          <span class="separator"></span>

          <div
            :class="{ active: isActive.ordered_list() }"
            class="menu-bar-icon d-inline-block"
            style="width: 25px; height: 25px"
          >
            <i
              class="fas fa-list-ol text-left mr-1"
              alt=""
              style="
                width: 20px;
                height: 20px;
                border-radius: 100%;
                cursor: pointer;
                position: relative;
                left: 3px;
              "
              @click="commands.ordered_list"
            ></i>
          </div>
          <div
            :class="{ active: isActive.bullet_list() }"
            class="menu-bar-icon d-inline-block"
            style="width: 25px; height: 25px"
          >
            <i
              class="fas fa-list-ul text-left mr-1"
              alt=""
              style="
                width: 20px;
                height: 20px;
                border-radius: 100%;
                cursor: pointer;
                position: relative;
                left: 3px;
              "
              @click="commands.bullet_list"
            ></i>
          </div>
        </div>
      </editor-menu-bar>
      <div v-show="isEmpty" class="mt-10 place-holder">
        {{ placeholderText }}
      </div>

      <editor-content class="p-3" :editor="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Strike,
  History,
} from "tiptap-extensions";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },

  props: {
    placeholderText: {
      required: false,
      default: "",
    },

    value: {
      default: "",
    },
  },

  data() {
    return {
      editor: null,
      // isEmpty: true,
    };
  },

  computed: {
    isEmpty() {
      return !this.value || this.value === "<p></p>";
    },
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }
      this.editor.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        new BulletList(),
        new ListItem(),
        new OrderedList(),
        new Bold(),
        new Italic(),
        new Strike(),
        new History(),
      ],
      content: this.value,
      autofocus: true,
      editable: true,
      onUpdate: () => {
        // HTML
        const html = this.editor.getHTML();
        this.$emit("input", html);
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style>
.menu-bar-icon.active {
  background-color: #e4e4e4;
  border-radius: 6px;
}
[contenteditable] {
  outline: 0px solid transparent;
}

.menu-bar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: #f8f8f8;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 35px;
}

.place-holder {
  display: flex;
  align-items: flex-start;
  position: absolute;
  margin-left: 16px;
  bottom: 1.8vh;
  /* top: 24.5vh; */
  color: #aaa;
  font-style: italic;
}

.separator {
  width: 7px;
  height: 25px;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  margin-right: 10px;
}

.button-size {
  width: 17px;
  height: 17px;
  border-radius: 100%;
}

.ProseMirror {
  margin-top: 0.75em;
  text-align: left;
}

.ProseMirror > p {
  margin-bottom: 1px;
  margin-top: 4px;
}

.ProseMirror > p > strong {
  font-weight: 600;
}

.ProseMirror > ul,
ol {
  padding: 0 1rem;
}

ul {
  list-style: unset;
}

.ProseMirror > h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

.ProseMirror > code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror > pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror > code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror > img {
  max-width: 100%;
  height: auto;
}

.ProseMirror > blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0d0d0d, 0.1);
}

.ProseMirror > hr {
  border: none;
  border-top: 2px solid rgba(#0d0d0d, 0.1);
  margin: 2rem 0;
}
</style>
